import { TabBar, TabProps, useTooltip, Tooltip, TabBarSkeleton } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { useGetGoalContentTypeFilter } from '../useGoalFilters'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { useQuery } from '@src/utils/queryParamsHooks'

interface Props {
  onFilterChange: (filters: FilterByInterface[], reset?: boolean) => void
}

export const GoalLevelSelector = ({ onFilterChange }: Props) => {
  const { query } = useQuery()
  const { data, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  const [selectedValue, setSelectedValue] = useState<
    EntityTypes.companyV2 | EntityTypes.department | EntityTypes.team
  >()
  const contentTypeFilter = useGetGoalContentTypeFilter(selectedValue)

  useEffect(() => {
    if (contentTypeFilter) {
      onFilterChange(
        [
          contentTypeFilter,
          ...(selectedValue === EntityTypes.companyV2
            ? [{ columnName: 'content_type_id', filters: [] }]
            : [
                { columnName: 'is_top_level', filters: [{ name: 'False', id: 'False' }] },
              ]),
        ],
        false,
      )
    }
  }, [selectedValue])

  useEffect(() => {
    if (data) {
      const contentTypeId = query?.content_type_id
      if (contentTypeId) {
        const model = data.find(({ id }) => id === Number(contentTypeId))?.model
        if (model) {
          switch (model) {
            case 'department':
              setSelectedValue(EntityTypes.department)
              return
            case 'teams':
              setSelectedValue(EntityTypes.team)
              return
            default:
          }
        }
      }
      setSelectedValue(EntityTypes.companyV2)
    }
  }, [data])

  return isLoading ? (
    <TabBarSkeleton variant="segmented fit" mx="auto">
      <TabBarSkeleton.Item />
    </TabBarSkeleton>
  ) : (
    <TabBar
      variant="segmented fit"
      mx="auto"
      value={selectedValue}
      onChange={value => {
        setSelectedValue(value || EntityTypes.companyV2)
      }}
    >
      <TabBarItemWithTooltip
        /* @ts-expect-error this icon works but it's added to types in ui-kit v14 and there is a significant effort needed to upgade */
        useIcon="CompanyFilled"
        tooltip="Company goals"
        to={EntityTypes.companyV2}
      />
      <TabBarItemWithTooltip
        useIcon="Bank"
        tooltip="Department goals"
        to={EntityTypes.department}
      />
      <TabBarItemWithTooltip
        useIcon="People"
        tooltip="Team goals"
        to={EntityTypes.team}
      />
    </TabBar>
  )
}

const TabBarItemWithTooltip = ({
  tooltip: content,
  ...tabBarItemProps
}: TabProps & { tooltip: string }) => {
  const tooltip = useTooltip()
  return (
    <>
      <TabBar.Item {...tooltip.getAnchorProps()} {...tabBarItemProps} />
      <Tooltip {...tooltip.getTargetProps()}>{content}</Tooltip>
    </>
  )
}
