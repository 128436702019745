import React, { useMemo } from 'react'
import { PageBody } from '@components/Page/PageBody'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { goalsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { API, selectorKeys } from '@src/constants/api'
import { performanceSettings } from '@src/api/performanceSettings'
import { selectCycleFrequencyTitle } from '@src/pages/OnboardingChecklistV2/Goals/index'
import { CYCLE_DURATION } from '@src/interfaces/settings'
import { connect } from 'lape'
import { useGetSelectors } from '@src/api/selectors'
import { RecommendedLabel } from '@src/pages/OnboardingChecklist/components/Recommended'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'

export const CycleDurationContent = () => {
  const { data: durations } = useGetSelectors(selectorKeys.cycle_durations)

  const options = useMemo(() => {
    if (durations && durations.length) {
      const descriptionsById = {
        quarterly:
          'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
        biannually:
          'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
        annually:
          "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
      }
      return durations.map(option => ({
        value: option,
        description: descriptionsById[option.id as CYCLE_DURATION],
        label:
          option.id === 'quarterly' ? (
            <RecommendedLabel label={option.name} />
          ) : (
            option.name
          ),
      }))
    }
    return []
  }, [durations])

  return (
    <>
      <PageBody>
        <LapeNewRadioButtons
          variant="group-items"
          highlightSelected
          name="cycle_duration"
          options={options}
        />
      </PageBody>

      <OnboardingActions
        config={goalsConfig}
        currentStep={selectCycleFrequencyTitle}
        isForm
        isLastStep={false}
        updateSteps
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL}
      />
    </>
  )
}

export const CycleDuration = connect(() => {
  return (
    <Form
      api={performanceSettings}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      forceParams={{ id: 'cycle' }}
      isExistingForm
    >
      <CycleDurationContent />
    </Form>
  )
})
